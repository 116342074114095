<template>
  <div class="grid justify-content-center">
    <div class="col md:col-5">
		<div class="mb-2">
        <router-link to="/">
          <Button
            label="Back"
            icon="pi pi-arrow-left"
            class="p-button-sm p-button-primary p-button-outlined p-button-rounded px-4"
          />
        </router-link>
      </div>
     <div class="card nice-shadow-6">
        <div class="my-3">
          <div class="text-2xl font-bold">Password Reset</div>
          <small class="text-500">
            Please provide the valid email address you used to register
          </small>
        </div>
        <Message severity="success" v-if="sentMsg">{{ sentMsg }}</Message>
        <form ref="observer" tag="form" @submit.prevent="sendEmail()">
          <div class="grid justify-content-between">
            <div class="col-10">
              <InputText
                class="w-full"
                v-model="formData.email"
                placeholder="Email"
                required
                type="email"
              />
            </div>
            <div class="col-2">
              <Button
                :loading="saving"
                type="submit"
                label="Send"
                icon="pi pi-envelope"
              />
            </div>
          </div>
        </form>
        <hr />
        <div class="text-primary">
          A link will be sent to your email containing the information you need
          for your password
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
export default {
  props: {},
  mixins: [PageMixin],
  data: function () {
    return {
      formData: {
        email: "",
      },
      saving: false,
      error: "",
      sentMsg: "",
    };
  },
  methods: {
    async sendEmail() {
      this.saving = true;
      this.$api.post("auth/forgotpassword", this.formData).then(
        (response) => {
          this.saving = false;
          this.sentMsg = response.data;
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
          this.sentMsg = "";
        }
      );
    },
  },
};
</script>
